/**
 * @author CHEN GUANGPENG
 * @date 2019/12/16
 */

import React from 'react';
import styles from './index.module.css';
const {
  newsCard,
  coverImg: coverImgStyle,
  content: contentStyle,
  title: titleStyle,
  summary: summaryStyle,
} = styles;
const toLink = link => {
  if (!link) return;
  window.open(link);
};
export default props => {
  const { title, link, summary, coverImg } = props;

  return (
    <div className={newsCard} onClick={() => toLink(link)}>
      <div className={coverImgStyle}>
        <img src={coverImg} alt={title}></img>
      </div>
      <div className={contentStyle}>
        <h3 className={titleStyle}>{title}</h3>
        <div className={summaryStyle}>{summary}</div>
      </div>
    </div>
  );
};
