/**
 * @author CHEN GUANGPENG
 * @date 2019/12/13
 */
import React, { useState, useEffect } from 'react';
import styles from '../assets/css/media.module.css';
import NewsCard from '../components/newsCard/index';
import { loadConfig } from '../service/api';
import Layout from '../components/layout/index';
const { appContainer, banner, mainContainer, tip } = styles;

const getConf = async () => {
  let news = [];
  try {
    const conf = await loadConfig();
    const { mediaReportsPage } = conf.data || {};
    if (mediaReportsPage) {
      news = mediaReportsPage.news || [];
    }
  } catch (e) {
    console.error(e);
  }
  return news;
};

export default () => {
  const [news, setNews] = useState([]);

  useEffect(() => {
    (async () => {
      const newsList = await getConf();
      setNews(newsList);
    })();
  }, []);

  return (
    <div className={appContainer}>
      <Layout>
        <div className={banner}></div>
        <div className={mainContainer}>
          {news.map((item, index) => (
            <NewsCard
              key={index}
              title={item.title}
              link={item.link}
              coverImg={item.coverImg}
              summary={item.summary}
            ></NewsCard>
          ))}
          <p className={tip}>更多媒体报道大事件请关注“路客美宿”公众号</p>
        </div>
      </Layout>
    </div>
  );
};
